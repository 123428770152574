<template>
	<Parallax
		:image="{
			backgroundImage: `url(${require('@/assets/images/lkld2.jpg')})`,
		}"
		:height="'70vh'"
		:opacity="0.7"
		:overlayColor="'100,100,100'"
		class="section"
	>
		<div class="container nav-pad" style="height: 70vh">
			<div class="vertical-center">
				<h1 class="large center primary black-shadow">
					Islamic Society of Greater Lakeland
				</h1>
				<h4 class="normal center italicized secondary black-shadow">
					Serving the communities of Lakeland, Bartow, Auburndale, Plant City, and Winter
					Haven
				</h4>
			</div>
		</div>
	</Parallax>

	<div class="section">
		<div class="container my-5 py-5">
			<a href="https://www.facebook.com/isglfl/" class="no-decor" target="_blank">
				<h1 class="center primary">Follow us on Facebook!</h1>
			</a>
			<a href="https://www.facebook.com/isglfl/" class="no-decor" target="_blank">
				<img
					src="../assets/images/FbPage.jpg"
					alt="fbpage"
					class="fb-image my-4 gold-border"
				/>
			</a>
			<!-- Paragraph of Lorem Ipsum -->
			<p class="center large">
				Follow our
				<a href="https://www.facebook.com/isglfl/" class="no-decor link" target="_blank"
					>Facebook page</a
				>
				for the most up-to-date listing of events and announcements. As a Muslim living in
				the greater Lakeland area, you are also welcome to join the affiliated private
				group once you've followed the Facebook page.
			</p>
		</div>
	</div>

	<Parallax
		:image="{
			backgroundImage: `url(${require('@/assets/images/Background.svg')})`,
			'background-color': '#fff',
		}"
		:height="'10vh'"
		:opacity="0.9"
		:overlayColor="'240, 240, 240'"
		class="section"
	>
		<!-- Three cards -->
		<div class="container py-5">
			<h1 class="center primary mt-5 mb-4 white-shadow">Our Services</h1>
			<div class="row mb-5 block-center">
				<div class="col-md-4">
					<div class="card">
						<!-- Card Icon -->
						<div class="card-icon">
							<i class="fa-solid fa-people-carry"></i>
						</div>
						<div class="card-body">
							<h5 class="card-title primary">Community Service</h5>
							<p class="card-text">
								Charity, community service, and social work are central to Islamic
								tradition
							</p>
							<router-link to="/services/community-service" class="btn btn-primary"
								>Read More <i class="fa-solid fa-arrow-right"></i
							></router-link>
						</div>
					</div>
				</div>
				<br />
				<div class="col-md-4">
					<div class="card">
						<!-- Card Icon -->
						<div class="card-icon">
							<i class="fa-solid fa-handshake-simple"></i>
						</div>
						<div class="card-body">
							<h5 class="card-title primary">Interfaith and Outreach</h5>
							<p class="card-text">
								ISGL collaborates with a variety of organizations in the spirit of mutual
								love and respect…
							</p>
							<router-link to="/services/interfaith-and-outreach" class="btn btn-primary"
								>Read More <i class="fa-solid fa-arrow-right"></i
							></router-link>
						</div>
					</div>
				</div>
				<br />
				<div class="col-md-4">
					<div class="card">
						<div class="card-icon">
							<i class="fa-solid fa-calendar-days"></i>
						</div>
						<div class="card-body">
							<h5 class="card-title primary">Other Events</h5>
							<p class="card-text">
								ISGL organizes a variety of events throughout the year, including...
							</p>
							<a href="#" class="btn btn-primary"
								>Read More <i class="fa-solid fa-arrow-right"></i
							></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Parallax>

	<Parallax
		:image="{
			backgroundImage: `url(${require('@/assets/images/HappyMuslims.jpg')})`,
		}"
		:overlayColor="'0, 0, 0'"
		:opacity="0.7"
		:height="'10vh'"
		class="section"
	>
		<div class="container py-5">
			<h1 class="center primary mt-5 mb-4">Pillars of Islam</h1>

			<!-- Row with five centered columns -->
			<div class="row mb-5 block-center">
				<div class="col">
					<!-- circle -->
					<div class="pillar">
						<div style="padding-top: 100%" class="vertical-center">
							<i class="fa-solid fa-hand-point-up"></i>
						</div>
					</div>
					<h3 class="primary center mt-3">Shahaadah</h3>
					<h5 class="secondary center">(Belief in Allah)</h5>
				</div>
				<br /><br /><br />
				<div class="col">
					<!-- circle -->
					<div class="pillar">
						<div style="padding-top: 100%" class="vertical-center">
							<i class="fa-solid fa-person-praying"></i>
						</div>
					</div>
					<h3 class="primary center mt-3">Salah</h3>
					<h5 class="secondary center">(The five daily prayers)</h5>
				</div>
				<br /><br /><br />
				<div class="col">
					<!-- circle -->
					<div class="pillar">
						<div style="padding-top: 100%" class="vertical-center">
							<i class="fa-solid fa-hand-holding-heart"></i>
						</div>
					</div>
					<h3 class="primary center mt-3">Zakat</h3>
					<h5 class="secondary center">(Charity to the poor)</h5>
				</div>
				<br /><br /><br />
				<div class="col">
					<!-- circle -->
					<div class="pillar">
						<div style="padding-top: 100%" class="vertical-center">
							<i class="fa-solid fa-moon"></i>
						</div>
					</div>
					<h3 class="primary center mt-3">Sawm</h3>
					<h5 class="secondary center">(Fasting in Ramadan)</h5>
				</div>
				<br /><br /><br />
				<div class="col">
					<!-- circle -->
					<div class="pillar">
						<div style="padding-top: 100%" class="vertical-center">
							<i class="fa-solid fa-kaaba"></i>
						</div>
					</div>
					<h3 class="primary center mt-3">Hajj</h3>
					<h5 class="secondary center">(Pilgrimage to Makkah)</h5>
				</div>
			</div>
		</div>
	</Parallax>

	<Parallax
		:image="{
			backgroundImage: `url(${require('@/assets/images/Background.svg')})`,
			'background-color': '#fff',
		}"
		:height="'10vh'"
		:opacity="0.95"
		:overlayColor="'240, 240, 240'"
		class="section"
	>
		<!-- Three cards -->
		<div class="container py-5">
			<h4 class="center mt-5 white-shadow mb-0">Support Us</h4>
			<h1 class="center primary mb-4 white-shadow">We Need Your Help</h1>
			<h4 class="normal center mb-4">
				Your generous support helps to provide the necessary resources and outreach
				materials to facilitate the interfaith relations, civic engagement, and
				community-building events as outlined in our mission.
			</h4>

			<div class="center">
				<a
					href="https://giv.li/t0po8n"
					target="_blank"
					class="btn btn-primary donate-btn mb-5"
				>
					<img
						src="../assets/images/Givelify.svg"
						alt="Givelify icon"
						height="50"
						width="50"
					/>
					<h4
						style="display: inline; vertical-align: middle"
						class="py-3 px-md-5 mx-md-4"
					>
						Give now via Givelify
					</h4>
				</a>
			</div>
		</div>
	</Parallax>

	<Parallax
		:image="{
			backgroundImage: `url(${require('@/assets/images/HappyMuslims2.jpg')})`,
		}"
		:overlayColor="'0, 0, 0'"
		:opacity="0.7"
		:height="'10vh'"
		class="section"
	>
		<div class="container py-5">
			<h1 class="center primary mt-5 mb-4">A Place for You</h1>
			<h4 class="center white normal">
				The Islamic Society of Greater Lakeland Inc. takes pride in our culture of
				diversity, open-mindedness, civic engagement, and community building. Get to know
				what makes us tick.
			</h4>

			<div class="row block-center mb-5 mt-5 white">
				<div class="col-6 center">
					<h3>Interested in becoming a member</h3>
					<a
						href="https://www.facebook.com/isglfl/"
						target="_blank"
						class="btn btn-primary mt-2"
						>Message us on Facebook</a
					>
				</div>
				<div class="col-6 center">
					<h3>Visit Us at Our Outreach Events</h3>
					<router-link to="/services/interfaith-and-outreach" class="btn btn-primary mt-2"
						>Interfaith and Outreach</router-link
					>
				</div>
			</div>
			<!-- MB-5 HERE -->
		</div>
	</Parallax>
</template>

<script>
	import Parallax from "../components/Parallax.vue";
	export default {
		components: {
			Parallax,
		},
	};
</script>

<style>
	.btn-primary,
	.btn-primary:visited,
	.btn-primary:active,
	.btn-primary:focus,
	.btn-primary:active:focus {
		background-color: var(--primary);
		color: rgb(255, 255, 255);
		border: none;
		outline: none;
		box-shadow: none;
		transition: all 0.25s ease-in-out;
	}

	.btn-primary:hover {
		background-color: var(--secondary);
	}

	.section {
		border-bottom: 5px solid var(--primary);
	}

	.large {
		font-size: 3.5rem;
	}

	p.large {
		font-size: 1.3rem;
	}

	.normal {
		font-weight: normal;
	}

	.center {
		text-align: center;
	}

	.italicized {
		font-style: italic;
	}

	.primary {
		color: var(--primary);
	}

	.secondary {
		color: var(--secondary);
	}

	.white {
		color: white;
	}

	.fb-image {
		margin: auto;
		display: block;
		width: 100%;
		border-radius: 20px;
	}

	.page-text {
		background-color: rgba(255, 255, 255, 0.5);
	}

	.no-decor,
	.no-decor:hover,
	.no-decor:focus,
	.no-decor:active,
	.no-decor:visited {
		text-decoration: none;
		color: var(--primary);
	}
	/* ====== CARDS ====== */
	.card-title {
		text-align: center;
		color: var(--primary);
		transition: all 0.4s ease-in-out;
	}

	.card {
		padding: 1rem;
		transition: all 0.2s ease-in-out;
	}

	.card:hover {
		background-color: var(--primary);
		color: white !important;
	}

	.card .btn-primary,
	.card .btn-primary:visited,
	.card .btn-primary:active,
	.card .btn-primary:focus,
	.card .btn-primary:active:focus,
	.card .btn-primary:hover {
		background-color: var(--primary);
		color: rgb(255, 255, 255);
		border: none;
		outline: none;
		box-shadow: none;
		margin: auto;
		display: block;
		width: fit-content;
		transition: all 0.25s ease-in-out;
		font-weight: 500;
	}

	.card:hover .btn-primary,
	.card:hover .btn-primary:visited,
	.card:hover .btn-primary:active,
	.card:hover .btn-primary:focus,
	.card:hover .btn-primary:active:focus,
	.card:hover .btn-primary:hover {
		background-color: white;
		color: var(--primary);
		border: none;
		outline: none;
		box-shadow: none;
	}

	.card:hover .card-title {
		color: white;
	}

	.card-body {
		width: 100%;
	}

	.card-icon {
		font-size: 5rem;
		color: var(--primary);
		text-align: center;
		margin: auto;
		display: block;
		width: fit-content;
		transition: all 0.4s ease-in-out;
	}

	.card:hover .card-icon {
		color: white;
	}

	.card-text {
		text-align: center;
	}

	/* ====== Pillars ====== */
	.pillar {
		width: 100% !important;
		height: 0;
		border-radius: 50%;
		border: 2px dotted white;
		background-color: rgba(var(--primaryRGB), 0.5);
		color: white;
		font-size: 5rem;
		text-align: center;
		margin: auto;
		display: flex;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		transition: all 0.4s ease-in-out;
		align-content: center;
		align-items: center;
		height: 0;
		padding-bottom: 100%;
	}

	.pillar i {
		margin: auto;
	}

	.col {
		flex: 1 0 0% !important;
		width: 18% !important;
		padding: 0 !important;
		margin: auto 20px auto !important;
	}

	.donate-btn {
		margin: auto;
		border-radius: 10rem;
	}

	.white-shadow {
		text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #fff, 0 0 4px #fff, 0 0 5px #fff,
			0 0 6px #fff, 0 0 7px #fff;
	}

	.black-shadow {
		text-shadow: 0 0 5px #000;
	}

	.link,
	.link:focus,
	.link:active,
	.link:visited {
		color: var(--primary);
		font-weight: 500;
	}

	.link:hover {
		color: var(--secondary);
	}

	.primary-hr {
		margin: 0 0 15px 0;
		height: 2px !important;
		background: var(--primary);
		opacity: 1;
	}

	.row.block-center {
		width: unset !important;
	}

	.nav-pad {
		padding-top: 60px;
	}

	.vertical-center {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: center;
		flex-direction: column;
	}

	.gold-border {
		border: 5px solid var(--primary);
	}

	.quran {
		background-color: rgba(146, 146, 146, 0.1);
		border-left: 10px solid var(--primary);
		margin: 1.5em 10px;
		padding: 0.5em 10px;
		quotes: "\201C""\201D";
		font-style: italic;
	}
	.quran:before {
		color: var(--primary);
		content: open-quote;
		font-size: 4em;
		line-height: 0.1em;
		margin-right: 0.25em;
		vertical-align: -0.4em;
	}

	.quran p {
		display: inline;
	}

    .mb-sm-4 {
        margin-bottom: 0!important;
    }

	@media (max-width: 1199.98px) {
		.large {
			font-size: 2.5rem;
		}

		p.large {
			font-size: 2.5vh !important;
		}

		.row {
			flex-direction: column !important;
			align-content: center !important;
			justify-content: center !important;
			align-items: center !important;
			display: flex !important;
			flex-wrap: nowrap !important;
		}

		.row.force-row {
			flex-direction: row !important;
		}

		.col {
			width: 200px !important;
		}

		.col .secondary.center {
			width: 100% !important;
			position: absolute !important;
			left: 50%;
			transform: translateX(-50%) !important;
		}

		.col-md-4 {
			width: 100% !important;
		}

		.gold-border {
			border: 3px solid var(--primary);
		}

		.col-8 {
			width: 100% !important;
		}

		.col-6 {
			margin-bottom: 3rem;
		}

        .mb-sm-4 {
            margin-bottom: 2rem!important;
        }
	}
</style>
