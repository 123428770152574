<template>
	<div>
		<nav class="navbar navbar-dark navbar-expand-xl">
			<div class="container">
				<router-link to="/" class="navbar-brand">
					<img
						src="../assets/images/ISGL.svg"
						height="50"
						alt="ISGL-Logo"
						title="Islamic Center of Greater Lakeland"
					/>
				</router-link>

				<button
					class="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarResponsive"
					@click="toggleNav"
				>
					<span v-if="!isOpen" class="navbar-toggler-icon"></span>
                    <i style="font-size: 1.5em!important; width: 30px!important; margin: auto" v-if="isOpen" class="fa-solid fa-xmark"></i>
				</button>

				<div
					class="nav-items"
					id="navbarResponsive"
					:style="
						useHamburger
							? (isOpen ? 'transform: translateY(0); height: calc(100vh - 60px)!important;' : 'transform: translateY(-1000px); height: calc(100vh - 60px)!important;') +
							  ' overflow-y: scroll!important'
							: 'transform: translateY(0)'
					"
				>
					<div class="navbar-nav">
						<div class="nav-item">
							<router-link to="/" class="nav-link">Home</router-link>
						</div>

						<div class="nav-item">
							<router-link to="/about" class="nav-link">About</router-link>
						</div>

						<Dropdown
							:header="'Masjids'"
							:useRouterLinks="true"
							:width="'230px'"
							:pageWidth="width"
							:dropContent="[
								{
									path: '/masjids/icol',
									title: 'Islamic Center of Lakeland',
								},
								{
									path: '/masjids/icopc',
									title: 'Islamic Center of Polk County',
								},
								{
									path: '/masjids/masjid-al-nur',
									title: 'Masjid Al-Nur',
								},
							]"
						/>

						<Dropdown
							:header="'Explore Islam'"
							:useRouterLinks="true"
							:width="'230px'"
							:pageWidth="width"
							:dropContent="[
								{
									path: '/explore-islam/what-is-islam',
									title: 'What is Islam?',
								},
								{
									path: '/explore-islam/who-are-muslims',
									title: 'Who are Muslims?',
								},
								{
									path: '/explore-islam/who-is-allah',
									title: 'Who is Allah?',
								},
								{
									path: '/explore-islam/what-do-muslims-believe',
									title: 'What Do Muslims Believe?',
								},
								{
									path: '/explore-islam/muslims-believe-in-jesus-too',
									title: 'Muslims Believe in Jesus Too',
								},
                                {
									path: '/islamic-trivia',
									title: 'Islamic Trivia',
								},
							]"
						/>

						<Dropdown
							:header="'Services'"
							:useRouterLinks="true"
							:width="'210px'"
							:pageWidth="width"
							:dropContent="[
								{
									path: '/services/community-service',
									title: 'Community Service',
								},
								{
									path: '/services/interfaith-and-outreach',
									title: 'Interfaith and Outreach',
								},
                                {
									path: '/services/first-friday',
									title: 'First Friday Outreach Event',
								},
							]"
						/>

                        <div class="nav-item">
							<router-link to="/support-us" class="nav-link">Support Us</router-link>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>

    <div class="page-cover" v-if="isOpen && useHamburger"></div>
</template>

<script>
	import Dropdown from "./Dropdown.vue";

	export default {
		components: {
			Dropdown,
		},
		watch: {
			width: function () {
				if (this.width < 1200) {
					this.useHamburger = true;
				} else {
					this.useHamburger = false;
                    this.$emit('close');
				}
			},
			useHamburger: function () {},
		},
		data() {
			return {
				isOpen: false,
				useHamburger: false,
				width: 0,
			};
		},
		methods: {
			toggleNav() {
				this.isOpen = !this.isOpen;

                if (this.isOpen && this.useHamburger) {
                    this.$emit('open');
                } else {
                    this.$emit('close');
                }
				console.log("toggleNav");
				console.log(this.isOpen);
			},
		},
		mounted() {
			window.addEventListener("resize", () => {
				this.width = window.innerWidth;
			});

			this.width = window.innerWidth;

			console.log("useHamburger: " + this.useHamburger);
			console.log("isOpen: " + this.isOpen);
		},
	};
</script>

<style>
	nav {
		width: 100%;
		z-index: 50;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
	}

	nav,
	.navbar,
	.navbar-dark,
	.navbar-expand-xl {
		width: 100%;
		padding: 0 20px 0 20px !important;
		background-color: rgba(0, 0, 0, 0.65);
		height: var(--navbar-height) !important;
		position: fixed;
		top: 0;
	}

	.nav-items {
		float: right;
		height: 60px !important;
	}

	.nav-items.mobile {
		display: none;
	}

	.navbar-brand img {
		filter: invert(72%) sepia(54%) saturate(296%) hue-rotate(1deg) brightness(96%)
			contrast(86%);
	}

	.nav-link {
		width: fit-content !important;
		height: var(--navbar-height);
		text-align: center;
		font-size: 14pt;
		transition: box-shadow 0.5s;
		display: inline-block;
	}

	.nav-link:hover {
		box-shadow: inset 0px -25px 15px -15px var(--red);
	}

	.row {
		width: 100% !important;
	}

	.nav-item {
		padding: 0 !important;
		padding-top: 5px !important;
		align-content: center;
		height: 60px !important;
		margin-left: 40px;
	}

	.router-link-active {
		color: white !important;
	}

	@media (max-width: 1199.98px) {
		.navbar-toggler {
			float: right;
			color: #dab876 !important;
		}

		.navbar-toggler:focus {
			box-shadow: none;
		}

		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28218, 184, 118, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
		}

		.nav-items {
			position: absolute;
			top: var(--navbar-height);
			left: 0;
			width: 100vw;
            height: unset!important;;
			transition: all 0.5s ease-in-out;
			transform-origin: left top;
            
		}

		.nav-item {
			background-color: transparent !important;
			margin: 0;
			height: unset !important;
            
		}

        .page-cover {
            width: 1000vw!important;
            height: 1000vh!important;
            overflow: hidden;
            z-index: 49;
            background-color: transparent;
            position: fixed;
            background-color: rgba(0, 0, 0, 0.85);
        }

        .nav-link {
            text-align: unset!important;
            padding-left: 2rem!important;
        }
	}
</style>
