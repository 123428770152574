import { createStore } from "vuex";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    getFirestore,
    doc,
    setDoc,
    getDoc,
    updateDoc,
    arrayUnion,
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBVzvZXrtHHInuDtCSuKBCR0INbYy4WYq4",
    authDomain: "isglfl.firebaseapp.com",
    projectId: "isglfl",
    storageBucket: "isglfl.appspot.com",
    messagingSenderId: "793848773926",
    appId: "1:793848773926:web:fb6659897b582bd16fd5e7",
    measurementId: "G-3WPY49HBPB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Connect to firestore and get database
const db = getFirestore(app);

const store = createStore({
    state: {
        email: "",
        firstFriday: [],
    },
    mutations: {
        setEmail(state, payload) {
            state.email = payload;
        },
        setFirstFriday(state, payload) {
            state.firstFriday = payload;
        }
    },
    actions: {
        async addEmail({ state }) {
            await updateDoc(doc(db, "mailing-list", "emails"), {
                emails: arrayUnion(state.email),
            });
        },
        // Get carousel array from first-friday collection
        async getFirstFriday({ commit }) {
            const res = await getDoc(doc(db, "services", "first-friday"));
            console.log("FIRST FRIDAY DATA: ", res.data());
            commit("setFirstFriday", res.data());
        }
    },
    getters: {
        getFirstFriday(state) {
            return state.firstFriday;
        }
    },
});

export default store;
