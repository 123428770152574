<template>
	<div class="app-display" id="nav">
		<Navbar @open="closeDisplay" @close="openDisplay" :key="forceReload"></Navbar>
	</div>

	<div class="app-display" :style="display">
		<router-view />
		<Footer />
        <notifications position="bottom right" />
	</div>

	<div class="easter-egg center">
		Bruh why yo screen so small smh<br /><br />
		<h1 class="center">-_-</h1>
	</div>
</template>

<script>
	import Navbar from "./components/Navbar.vue";
	import Footer from "./components/Footer.vue";

	export default {
		watch: {
			$route(to, from) {
				this.openDisplay();
				this.forceReload++;
			},
		},
		components: {
			Navbar,
			Footer,
		},
		data() {
			return {
				display: "",
				forceReload: 0,
			};
		},
		methods: {
			openDisplay() {
				this.display = "";
			},
			closeDisplay() {
				this.display = "display: none!important;";
			},
		},
	};
</script>

<style>
	:root {
		--navbar-height: 60px;
		--primary: #dab876;
		--primaryRGB: 218, 184, 118;
		--secondary: #d6c299;
        --off-white: #fafafa;
	}
	.page-container {
		margin-top: 80px;
	}

	body {
		background-color: var(--off-white);
		color: rgb(20, 20, 20);
	}
</style>

<style scoped>
	.easter-egg {
		display: none;
	}

	@media (max-width: 279px) {
		.app-display {
			display: none;
		}

        .easter-egg {
            display: block;
        }
	}
</style>
