import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/about",
		name: "About",
		component: () => import("../views/About.vue"),
	},
	{
		path: "/services/interfaith-and-outreach",
		name: "Interfaith and Outreach",
		component: () => import("../views/InterfaithAndOutreach.vue"),
	},
	{
		path: "/services/community-service",
		name: "Community Service",
		component: () => import("../views/CommunityService.vue"),
	},
    {
		path: "/services/first-friday",
		name: "First Friday",
		component: () => import("../views/FirstFriday.vue"),
	},
    {
        path: "/explore-islam/who-is-allah",
        name: "Who is Allah",
        component: () => import("../views/WhoIsAllah.vue"),
    },
    {
        path: "/explore-islam/what-is-islam",
        name: "What is Islam",
        component: () => import("../views/WhatIsIslam.vue"),
    },
    {
        path: "/explore-islam/who-are-muslims",
        name: "Who are Muslims",
        component: () => import("../views/WhoAreMuslims.vue"),
    },
    {
        path: "/explore-islam/what-do-muslims-believe",
        name: "What Muslims Believe",
        component: () => import("../views/WhatDoMuslimsBelieve.vue"),
    },
    {
        path: "/explore-islam/muslims-believe-in-jesus-too",
        name: "Muslims Believe in Jesus Too",
        component: () => import("../views/MuslimsBelieveInJesusToo.vue"),
    },
    {
        path: "/masjids/icol",
        name: "Islamic Center of Lakeland",
        component: () => import("../views/ICOL.vue"),
    },
    {
        path: "/masjids/masjid-al-nur",
        name: "Masjid Al-Nur",
        component: () => import("../views/MasjidAlNur.vue"),
    },
    {
        path: "/masjids/ICOPC",
        name: "Islamic Center of Polk County",
        component: () => import("../views/ICOPC.vue"),
    },
    {
        path: "/support-us",
        name: "Support Us",
        component: () => import("../views/SupportUs.vue"),
    },
    {
        path: "/islamic-trivia",
        name: "Islamic Trivia",
        component: () => import("../views/IslamicTrivia.vue"),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });

export default router;
